export const PARDOT_FORM_ID = process.env.REACT_APP_PARDOT_FORM_ID

export const PARDOT_SERVICE_ENDPOINT =
  process.env.REACT_APP_PARDOT_SERVICE_ENDPOINT

export const STACK_NAME = process.env.REACT_APP_STACKNAME

export const HUBSPOT_ID = '19620401'
const getHubspotFormAPIEndpoint = ({ formGuid, hubspotId = HUBSPOT_ID }) =>
  `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotId}/${formGuid}`

export const HUBSPOT_FORM_API = getHubspotFormAPIEndpoint({
  formGuid: 'beeaea89-eb26-4c71-baac-63e6ba083314',
})

// Pre-rendering
export const isPreRendering = navigator.userAgent === 'ReactSnap'
