import React, { Component } from 'react'
import YouTube from 'react-youtube'

import { isPreRendering } from '../../config'

import s from './css/VideoBox.module.css'

class VideoBox extends Component {
  onVideoReady() {
    if (this.props.onReady) this.props.onReady()
  }

  render() {
    const videoOpts = Object.assign({}, defaultVideoOpts, this.props.opts)

    return (
      <div className={s.VideoBox}>
        {!isPreRendering && (
          <YouTube
            videoId={this.props.videoId}
            opts={videoOpts}
            onReady={() => {
              this.onVideoReady()
            }}
            onStateChange={this._videoStateChange}
            key={'YouTube_Player_' + this.props.videoId}
          />
        )}
      </div>
    )
  }
}

const defaultVideoOpts = {
  height: '360',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    modestbranding: 1,
    start: 0,
  },
}

VideoBox.defaultProps = {
  className: '',
  videoId: 'j3EQJ1-AcIo',
  opts: defaultVideoOpts,
}

export default VideoBox
