import React, { PureComponent } from 'react'

import coopLogos from './images/coop-logos.png'
import voyagerLogo from './images/voyager-logo.svg'

import s from './css/Footer.module.css'

class Footer extends PureComponent {
  render() {
    const year = new Date().getFullYear()

    return (
      <footer className={s.footer}>
        <div className={s.row}>
          <img
            className={s.voyagerLogo}
            src={voyagerLogo}
            alt="Health Voyager Logo"
          />
          <nav className={s.links}>
            <ul>
              <li>
                <a
                  href="https://www.klick.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </nav>
          <div className={s.coopLogos}>
            <p>In collaboration with</p>
            <img src={coopLogos} alt="Co-op Logos" />
          </div>
        </div>
        <div className={s.copyRight}>
          &copy; {year} HealthVoyager<sup>&trade;</sup> is a trademark of Klick
          Inc. and Boston Children's Hospital.
        </div>
      </footer>
    )
  }
}

export default Footer
