import React, { PureComponent } from 'react'
import { PropTypes } from 'prop-types'

import particleLogo from './images/particle-logo.svg'

import s from './css/ParticleSection.module.css'

class ParticleSection extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    return (
      <section className={s.particleSection} {...this.props}>
        {this.props.children}
        <img
          className={s.particle1}
          src={particleLogo}
          alt="Health Voyager Logo"
        />
        <img
          className={s.particle2}
          src={particleLogo}
          alt="Health Voyager Logo"
        />
        <img
          className={s.particle3}
          src={particleLogo}
          alt="Health Voyager Logo"
        />
      </section>
    )
  }
}

export default ParticleSection
