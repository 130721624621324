import React, { PureComponent } from 'react'
import Loadable from 'react-loadable'

import { isPreRendering } from '../../config'
import Loader from '../Loader/Loader'

const FormContactLoadable = isPreRendering
  ? Loader
  : Loadable({
      loader: () => import('./ContactFormApp'),
      loading: Loader,
    })

export default class App extends PureComponent {
  render() {
    return <FormContactLoadable {...this.props} />
  }
}
