import React, { Component } from 'react'
import SmoothScroll from 'smooth-scroll'

import { P } from './components/BaseElements/BaseElements'
import Button from './components/Button/Button'
import ContactForm from './components/ContactForm/ContactFormLoadable'
import ContentBlock from './components/ContentBlock/ContentBlock'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Hero from './components/Hero/Hero'
import ParticleSection from './components/ParticleSection/ParticleSection'
import Section from './components/Section/Section'
import VideoBlock from './components/VideoBlock/VideoBlock'

import s from './App.module.css'

class App extends Component {
  componentDidMount() {
    this.smoothScroll = new SmoothScroll('.scroll-link')
  }

  render() {
    return (
      <div>
        <Header />

        <Hero />

        <Section id="PatientJourney">
          <ContentBlock h2="Augmenting the patient journey">
            <P>
              Traditionally, physicians share findings using printouts directly
              from the patient's medical record. While highly informational,
              many patients admit to being overwhelmed and confused by the
              clinical results of diagnostic procedures.
            </P>
            <P>
              Without a clear understanding, and unable to translate complicated
              scientific language into concrete concepts, patients are not
              equipped to ask questions, research treatment options, share
              information with family members, or advocate for themselves.
            </P>
            <P>
              HealthVoyager gives patients a new way to interact with their
              health data. By providing findings in a personalized and
              approachable way, patients are now able to fully comprehend their
              individual results and connect with their healthcare providers in
              a new way.
            </P>
          </ContentBlock>
        </Section>

        <ParticleSection id="EmpoweringPatients">
          <ContentBlock
            h2="Empowering patients"
            h3="HealthVoyager simplifies a process that has been complex for
                both healthcare&nbsp;provider and patients"
            hasMargin
          />

          <VideoBlock
            h2="The Future of Patient Engagement"
            videoId="j3EQJ1-AcIo"
          >
            <P>
              Using a modern and simplified interface healthcare professionals
              can easily drag and drop findings directly onto a digital
              representation of the patient's anatomy. Once completed, the
              application can be used to generate a simplified report that
              contains a unique scannable code linked to a specific patient.
              Designed to be integrated into the hospital electronic medical
              record, all information is stored in a HIPAA compliant way, and
              can be used to help populate the internal hospital patient record.
            </P>
            <P>
              Patients are able to access a mobile app to view their health
              findings at any time. Upon scanning the report generated by the
              healthcare professional, they are able to customize a personal
              avatar that will guide them through their own medical findings.
              Leveraging the latest in data visualization and virtual reality,
              patients are able to explore the outcomes of the their procedures.
              Every patient will get a unique view that is tailored to their own
              findings, and will be able to fully understand what is happening
              within their own body.
            </P>
          </VideoBlock>

          <VideoBlock
            h2="Transforming the Physician-Patient Relationship Through Customization"
            videoId="8VsKsJ7l-6Y"
            alt
          >
            <P>
              HealthVoyager&trade; is leading the frontier of new opportunities
              through VR in the healthcare industry. While hospitals have
              started using VR in healthcare, most use it to help distract
              patients as a part of pain management. Current implementations for
              pain management are only scratching the surface of what’s
              possible. Customizable patient education experiences like
              HealthVoyager&trade; have the potential to directly impact the
              course of a patient’s illness in a major way.
            </P>
            <P>
              This platform also takes a cue from precision medicine – the
              personalization of drug therapy and genomics for effective disease
              prevention, diagnosis, and treatment – and aims to create
              precision education for patients and their families.
            </P>
          </VideoBlock>

          <div className={s.buttonContainer}>
            <Button
              label="I'm Interested"
              className="scroll-link "
              href="#ContactUs"
            />
          </div>
        </ParticleSection>

        <Section id="ContactUs">
          <ContentBlock h2="Get in Touch">
            <P>
              Looking to learn more about HealthVoyager? We’re happy to hear
              from you. Fill out the form below and will get in touch as soon as
              we can.
            </P>
          </ContentBlock>
          <ContactForm />
        </Section>

        <Footer />
      </div>
    )
  }
}

export default App
