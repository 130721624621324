import React, { PureComponent } from 'react'

import healthVoyagerLogo from './images/health-voyager-logo.svg'

// import facebookLogo from "./images/facebook.png";
// import twitterLogo from "./images/twitter.png";
// import instagramLogo from "./images/instagram.png";
import s from './css/Header.module.css'

class Header extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    // Bind functions
    this.toggleMenu = this.toggleMenu.bind(this)
    this.menuClick = this.menuClick.bind(this)
  }

  toggleMenu() {
    this.setState({
      open: !this.state.open,
    })
  }

  menuClick() {
    if (this.state.open) this.toggleMenu()
  }

  render() {
    const { open } = this.state

    const menu = (
      <ul className={s.menuList}>
        <li>
          <a
            className="scroll-link"
            href="#PatientJourney"
            onClick={this.menuClick}
          >
            Patient Journey
          </a>
        </li>
        <li>
          <a
            className="scroll-link"
            href="#EmpoweringPatients"
            onClick={this.menuClick}
          >
            Empowering Patients
          </a>
        </li>
        <li>
          <a className="scroll-link" href="#ContactUs" onClick={this.menuClick}>
            Contact Us
          </a>
        </li>
      </ul>
    )

    /*
    const social = (
      <ul className={s.socialList}>
        <li>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookLogo} alt="Facebook" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterLogo} alt="Twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramLogo} alt="Instagram" />
          </a>
        </li>
      </ul>
    );
    */

    return (
      <div className={s.Header} {...(open ? { open } : {})}>
        <div className={s.logo}>
          <img src={healthVoyagerLogo} alt="Health Voyager Logo" />
        </div>
        <div className={s.desktop}>
          <div className={s.menu}>{menu}</div>
          {
            // <div className={s.social}>{social}</div>
          }
        </div>
        <div className={s.menuButton} onClick={this.toggleMenu}>
          <label htmlFor="menuButton">
            <div />
            <div />
            <div />
          </label>
        </div>
        <div className={s.mobileMenu} {...(open ? { open } : {})}>
          {menu}
          {
            // social
          }
        </div>
      </div>
    )
  }
}

export default Header
