import React, { Component } from 'react'

import smartphoneImage from './images/smartphone.png'

// import smartphoneImage from "./images/smartphone-device.jpg";
// import smartphoneScreen from "./images/smartphone-screen.jpg";
import s from './css/Smartphone.module.css'

class Smartphone extends Component {
  render() {
    return (
      <div className={s.smartphone}>
        <img src={smartphoneImage} alt="Smartphone" />
        {/* <div className={s.screen}>
          <img src={smartphoneScreen} alt="Smartphone Screen" />
        </div> */}
      </div>
    )
  }
}

export default Smartphone
