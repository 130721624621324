import React, { Component } from 'react'

import { H1, P } from '../BaseElements/BaseElements'
import Button from '../Button/Button'
import Smartphone from '../Smartphone/Smartphone'

import heroImage from './images/hero.jpg'
import heroMask from './images/hero-mask.svg'
import mouseIcon from './images/mouse-icon.png'

import s from './css/Hero.module.css'

class Hero extends Component {
  render() {
    return (
      <div className={s.Hero}>
        <div className={s.background}>
          <img
            className={s.image}
            src={heroImage}
            alt="Doctor Talking to Patient"
          />
          <img className={s.mask} src={heroMask} alt="Presentational Mask" />
        </div>
        <div className={s.content}>
          <H1>The new standard in patient engagement</H1>
          <P>
            HealthVoyager allows patients to be fully engaged and immersed in
            their own health journey. Leveraging patient data found within the
            hospital electronic medical record, HealthVoyager allows patients to
            visualize and explore their health in a way never before possible.
          </P>
          <Button
            label="Learn More"
            className="scroll-link"
            href="#PatientJourney"
          />
          <Button
            label="Contact Us"
            className="scroll-link"
            href="#ContactUs"
          />
          <div className={s.mouseIcon}>
            <img src={mouseIcon} alt="PC Mouse Icon" />
          </div>
        </div>
        <Smartphone />
      </div>
    )
  }
}

export default Hero
