import React from 'react'

import s from './css/Section.module.css'

const Section = (props) => {
  const { children, ...restProps } = props
  return (
    <section className={s.section} {...restProps}>
      {children}
    </section>
  )
}

export default Section
