import React, { PureComponent } from 'react'
import cx from 'classnames'
import { PropTypes } from 'prop-types'

import { H2, H3 } from '../BaseElements/BaseElements'

import s from './css/ContentBlock.module.css'

class Section extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    h2: PropTypes.string.isRequired,
    h3: PropTypes.string,
  }

  static defaultProps = {
    h2: 'H2 Text Here',
  }

  render() {
    return (
      <div
        className={cx(s.contentBlock, {
          [s.hasMargin]: this.props.hasMargin,
        })}
      >
        {this.props.h2 && <H2>{this.props.h2}</H2>}
        {this.props.h3 && <H3>{this.props.h3}</H3>}
        {this.props.children}
      </div>
    )
  }
}

export default Section
