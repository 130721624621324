import React, { PureComponent } from 'react'
import cx from 'classnames'
import { PropTypes } from 'prop-types'

import s from './css/Button.module.css'

class Button extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
  }

  static defaultProps = {
    label: 'Button',
    href: '#',
  }

  render() {
    const { className, href, label } = this.props
    return (
      <a className={cx(s.Button, className)} href={href}>
        {label}
      </a>
    )
  }
}

export default Button
