import React, { PureComponent } from 'react'
import cx from 'classnames'
import { PropTypes } from 'prop-types'

import { H2, H3 } from '../BaseElements/BaseElements'
import VideoBox from '../VideoBox/VideoBox'

import s from './css/VideoBlock.module.css'

class VideoBlock extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    h2: PropTypes.string,
    h3: PropTypes.string,
    videoId: PropTypes.string.isRequired,
    alt: PropTypes.bool,
  }

  render() {
    const { h2, h3, videoId, alt, children, ...restProps } = this.props

    return (
      <div
        className={cx(s.videoBlock, s.flexContainer, { [s.alt]: alt })}
        {...restProps}
      >
        <div className={cx(s.flexColumn, s.videoColumn)}>
          <div className={s.videoHeadingsMobile}>
            {h2 && <H2>{h2}</H2>}
            {h3 && <H3>{h3}</H3>}
          </div>
          <VideoBox videoId={videoId} />
        </div>
        <div className={cx(s.flexColumn, s.textColumn)}>
          <div className={s.videoHeadingsDesktop}>
            {h2 && <H2>{h2}</H2>}
            {h3 && <H3>{h3}</H3>}
          </div>
          {children}
        </div>
      </div>
    )
  }
}

export default VideoBlock
