import React, { PureComponent } from 'react'
import cx from 'classnames'
import { PropTypes } from 'prop-types'

import s from './BaseElements.module.css'

class H1 extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    const { children, className, ...props } = this.props
    return (
      <h1 className={cx(s.h1, className)} {...props}>
        {children}
      </h1>
    )
  }
}

class H2 extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    const { children, className, ...props } = this.props
    return (
      <h2 className={cx(s.h2, className)} {...props}>
        {children}
      </h2>
    )
  }
}

class H3 extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    const { children, className, ...props } = this.props
    return (
      <h3 className={cx(s.h3, className)} {...props}>
        {children}
      </h3>
    )
  }
}

class P extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    const { children, className, ...props } = this.props
    return (
      <p className={cx(s.p, className)} {...props}>
        {children}
      </p>
    )
  }
}

export { H1, H2, H3, P }
